import styled from 'styled-components';

interface IAddressCard {
  active?: boolean
}

export const StyledAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAddressCard = styled.div<IAddressCard>`
  background: white;
  padding: 24px;
  border: 2px solid;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  cursor: pointer;
  ${props => props.active && `
    border-color: blue;
  `}

  & header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    & h3{
      margin:0;
      ${props => props.theme.mixins.heading}; 
    }
    & h3, & i{
      ${props => props.active && `
        color: blue;
      `}}
  }

  & address {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-style: normal;

    & > span {
      display: flex;
      flex-direction: column;
      ${props => props.theme.mixins.bold}; 
      color: ${props => props.theme.colors.darkGrey};
      
  
    & > span {
      ${props => props.theme.mixins.label};
      color: ${props => props.theme.colors.grey};
    }
  }

  }
`;
