import Cookies from "js-cookie";
import { useAppContext } from "../../context/AppContext"
import axios from "axios";
import { cookiesList } from "./cookiesList";
import { useQuestionContext } from "../../context/QuestionContext";

const submitIpTracking = async (obj: Object) => {
  const outputObj = JSON.stringify(obj)

  const submissionVars = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await axios
    .post(process.env.REACT_APP_INTEGRATION_API_ENDPOINT || '', outputObj, submissionVars)
    .then((response) => {
      console.log('IP tracking submitted successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error submitting IP tracking:', error);
    });

  return
}

const cookieTrackingValues = () => {
  const cookieObj: any = {}

  cookiesList.forEach(cookie => {
    if (Cookies.get(cookie.cookieKey)) {
      cookieObj[cookie.attributeName] = Cookies.get(cookie.cookieKey)
    }
  })

  return cookieObj
}

function getGaTracker() {
  // @ts-expect-error
  const ga = window.ga
  if (typeof ga.getAll === "function") {
    const trackers = ga.getAll()
    if (trackers.length > 0) {
      const tracker = trackers.find(
        (t: any) => {
          return t.get("trackingId") && t.get("userId")
        }
      )

      if (tracker) return tracker

      return trackers.find((t: any) => t.get("trackingId"))
    }
  }
  return null
}

const gaUserInfo = () => {
  // @ts-expect-error
  const ga = window.ga
  if (ga) {

    const tracker = getGaTracker()
    if (tracker) {
      const gaUserId = tracker.get("userId")

      const form: any = {}

      form.gaTrackId = tracker.get("trackingId") || undefined
      form.gaUserId = Cookies.get("_sfmc_id") || gaUserId || undefined
      form.gaClientId = tracker.get("clientId") || undefined

      return form
    }
    return {}
  }
  return {}
}

export const useIpTrackingTask = () => {

  const { appStage, user, program, country } = useAppContext();
  const { questionState } = useQuestionContext()

  const contextTrackingValues = {
    type: 'INPROGRESS',
    program,
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    phone: user?.phoneNumber,
    campus: questionState?.sessionValues?.campus || questionState?.sessionValues?.campus,
    country: country,
  }

  const enquiryToInProgressTrack = async () => {

    if (appStage?.toLowerCase() === 'enquiry') {

      const resObject = {
        ...contextTrackingValues,
        ...cookieTrackingValues(),
        ...gaUserInfo(),
        pageUrl: location.origin,
      }

      await submitIpTracking(resObject)
    }

    return;
  };

  return enquiryToInProgressTrack;
};

