import {Button, Icon} from 'hult-toolkit';
import {IAddressObj} from '../../QuestionInputs/AddressInput/address.types';
import {StyledAddressCard} from './address.styles';

interface AddressCardProps {
  title?: string;
  onClick?: () => void;
  address?: IAddressObj;
  allowEdit?: boolean;
  active?: boolean;
}

export const AddressCard: React.FC<AddressCardProps> = ({
  title,
  onClick,
  address,
  allowEdit,
  active,
}) => {
  return (
    <StyledAddressCard onClick={onClick} active={active}>
      <header>
        {title && <h3>{title}</h3>}
        {active && <Icon icon="check-circle" />}
      </header>
      <address>
        {address?.address1 && (
          <span>
            <span>Address Line 1</span>
            {address.address1}
          </span>
        )}
        {address?.address2 && (
          <span>
            <span>Address Line 2</span>
            {address.address2}
          </span>
        )}
        {address?.city && (
          <span>
            <span>City</span>
            {address.city}
          </span>
        )}
        {address?.postalCode && (
          <span>
            <span>Post/Zip code</span>
            {address.postalCode}
          </span>
        )}
        {address?.addressState && (
          <span>
            <span>State</span>
            {address.addressState}
          </span>
        )}
        {address?.countryName && (
          <span>
            <span>Country</span>
            {address.countryName}
          </span>
        )}
      </address>
      {allowEdit && (
        <Button
          label="Edit"
          icon={{name: 'pencil'}}
          size="small"
          variant="secondary"
        />
      )}
    </StyledAddressCard>
  );
};
