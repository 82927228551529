import { useAppContext, useAppDispatch } from "../../context/AppContext";
import { readCache } from "../../context/AppContext/reducerFunctions";
import { useQuestionContext } from "../../context/QuestionContext";
import { useStepContext } from "../../context/StepContext";
import { useNavigate } from 'react-router-dom';
import {
  GetApplicationContextDocument,
  useCompleteApplicationStepMutation,
  useUpdateApplicationStepMutation
} from "../../graphql";
import { useIpTrackingTask } from "../../services/integration";

export const useSubmitProgram = () => {
  const { selectedApplication, session, program } = useAppContext();
  const { questionState, questionDispatch } = useQuestionContext();
  const navigate = useNavigate()
  const { allQuestionMappings, questionMappingList, savedValues, submitObj } = questionState;

  const { stepState } = useStepContext();

  const ipTrack = useIpTrackingTask();
  const dispatch = useAppDispatch();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation();
  const [updateApplicationStepMutation] = useUpdateApplicationStepMutation();

  const finishProgramCompletion = () => {

    try {
      dispatch({
        type: 'complete-step',
        payload: {
          guid: selectedApplication || '',
          step: 'program',
        },
      });

      dispatch({
        type: 'ga-event',
        payload: {
          event: 'stdapp_sectionComplete',
          content: `(3.0) - program - ${program}`,
        },
      });

      questionDispatch({
        type: 'complete-step',
        payload: {
          stepCompleted: true
        }
      })

    } catch (error) {
      console.log('finishProgramCompletion', error)
    }
  }

  const submitProgram = async () => {
    const programVariables = {
      guid: selectedApplication || '',
      step: 'program',
    };

    const includedLastVal = { ...stepState, [submitObj.qMapping]: submitObj.value }
    const includedLastSession = { ...questionState.sessionValues, [submitObj.qMapping]: submitObj.name }

    const submittingProgramObj = submitObj.updated ? includedLastVal : stepState
    const submittingProgramSession = submitObj.updated ? includedLastSession : questionState.sessionValues

    const hasUpdated = questionMappingList.some((key: any) => submittingProgramObj[key] !== savedValues[key]);

    if (hasUpdated) {
      const updatedValues: any = {};
      const updatedSessionValues: any = {};

      allQuestionMappings.forEach((key: any) => {
        // HULT-15611 - This should be re-implemented to be less hard coded
        // to handle earlyAction and specialisation setting/unsetting consistently.
        // Default case is to update values based on context, including if null so that values such as specialization can be removed from dynamodDB.
        if (key !== 'earlyAction') {
          updatedValues[key] = submittingProgramObj[key];
          updatedSessionValues[key] = submittingProgramSession[key];
        } else if (key === 'earlyAction' && submittingProgramObj[key] !== null) { // This is an edge case, where setting earlyAction to null can cause undesired downstream behaviour.
          updatedValues[key] = submittingProgramObj[key];
          updatedSessionValues[key] = submittingProgramSession[key];
        }
      });

      const addProgramStep = async () => {
        try {
          await updateApplicationStepMutation({
            variables: {
              ...programVariables,
              ...updatedValues,
            },
            onCompleted() {
              console.log('updateApplicationStepMutation completed');
            },
            onError(error) {
              console.error('updateApplicationStepMutation error', error);
            },
          });
        } catch (err) {
          console.log("addProgramStep error", err);
        }
      };

      const addToSession = async () => {

        console.log('submitting session data', submittingProgramSession)

        try {
          await updateApplicationStepMutation({
            variables: {
              guid: selectedApplication || '',
              step: 'session_data',
              ...submittingProgramSession,
            },
            onCompleted() {

              const newSession = {
                ...session,
                ...submittingProgramSession,
              }

              dispatch({
                type: 'update-session',
                payload: newSession,
              });
            },
          });
        } catch (err) {
          console.log("addToSession error", err);
        }
      };

      const completeProgramStep = async () => {

        console.log('completing program Step...')

        try {
          await completeApplicationStepMutation({
            variables: programVariables,
            onCompleted() {

              const cacheData: any = readCache(selectedApplication || '', GetApplicationContextDocument);

              dispatch({
                type: 'update-state',
                payload: cacheData?.studentApplication,
              });

              finishProgramCompletion()
            },
            refetchQueries: ['GetApplicationContext'],
            awaitRefetchQueries: true,
            onError(error) {
              console.error('completeApplicationStepMutation error', error);
            },
          });
        } catch (err) {
          console.log("completeProgramStep error", err);
        }
      };

      await addProgramStep();
      await addToSession();
      // 27.09.2024 - HULT-14718 - In Progress Tracking 
      // functionality disable due to undesired behavior (S4S api user exhibiting unexpected behavior and overwriting application program)
      // await ipTrack()
      await completeProgramStep();

      navigate('/')
    } else {
      navigate('/')
    }
  };

  return submitProgram;
};
