export const cookiesList = [
  { cookieKey: '_source', attributeName: 'source' },
  { cookieKey: '_medium', attributeName: 'medium' },
  { cookieKey: '_campaign', attributeName: 'campaign' },
  { cookieKey: '_content', attributeName: 'content' },
  { cookieKey: '_term', attributeName: 'term' },
  { cookieKey: '_entry', attributeName: 'entryUrl' },
  { cookieKey: '_first_source', attributeName: 'firstSource' },
  { cookieKey: '_first_medium', attributeName: 'firstMedium' },
  { cookieKey: '_first_campaign', attributeName: 'firstCampaign' },
  { cookieKey: '_first_content', attributeName: 'firstContent' },
  { cookieKey: '_first_term', attributeName: 'firstTerm' },
  { cookieKey: '_first_entry', attributeName: 'firstEntryUrl' },
  { cookieKey: '_first_referral', attributeName: 'firstReferralUrl' },
  { cookieKey: '_referrer', attributeName: 'referralUrl' },
  { cookieKey: '_gclid', attributeName: 'gCliId' },
  { cookieKey: '_fbclid', attributeName: 'fbCliId' },
  { cookieKey: '_mcJobId', attributeName: 'mcJobId' },
  { cookieKey: 'uLat', attributeName: 'uLat' },
  { cookieKey: 'uLon', attributeName: 'uLon' }
]